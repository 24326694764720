<div class="progress-fake-bar-container" *ngIf="isRunning"
     [ngClass]="{
        'show-back': showBackground && !whiteBackground,
        'white-background' : showBackground && whiteBackground
    }"
>

    <div class="progress-fake-info-wrapper-row" *ngIf="row;else notRow">
        <progress-bar class="progress-fake-bar-wrapper"
                      [progressValue]="progressValue">
        </progress-bar>

        <div class="percent-section"
             [ngClass]="{
                'color-black': !showBackground || whiteBackground
            }"
        >
            {{ progressValue }}%
        </div>
    </div>

    <ng-template #notRow>
        <div class="progress-fake-info-wrapper">
            <div class="percent-section"
                 [ngClass]="{
                'color-black': !showBackground || whiteBackground
            }"
            >
                {{ progressValue }}%
            </div>

            <progress-bar class="progress-fake-bar-wrapper"
                          [progressValue]="progressValue">
            </progress-bar>

            <div class="progress-fake-text-section" [ngClass]="{
                'color-black': whiteBackground
            }">
                {{ text }}
            </div>
        </div>
    </ng-template>

</div>
