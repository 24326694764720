import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ProgressBarComponent} from '../progress-bar/progress-bar.component';
import {NgIf, NgClass} from '@angular/common';
import {BaseFakeProgress} from "../base-fake-progress/base-fake-progress";

@Component({
    selector: 'app-progress-fake-bar',
    templateUrl: './progress-fake-bar.component.html',
    styleUrls: ['./progress-fake-bar.component.css'],
    standalone: true,
    imports: [NgIf, NgClass, ProgressBarComponent]
})
export class ProgressFakeBarComponent extends BaseFakeProgress implements OnChanges, OnDestroy {
    @Input() showBackground: boolean = true;
    @Input() whiteBackground: boolean = false;
    @Input() text: string;
    @Input() row: boolean;

    constructor() {
        super();
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.mainLogic(changes)
    }

    ngOnDestroy(): void {
        clearInterval(this.mediaInterval)
    }


}
